"use client";

import { Program, StandardLP } from "@/services/types";
import { formatStringArray } from "@/utils/Functions";
import { setItem } from "@/utils/Functions/storageUtils";
import "./index.scss";
import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";
import { Swiper } from "swiper";
import { Mousewheel, Navigation } from "swiper/modules";
import { SwiperSlide } from "swiper/react";
import GSIconVideo from "../../../../../public/images/new/GS-Icon-Video.svg";
import { routeVariables } from "@/utils/routestrings";
import SliderArrows from "../SliderArrows";
import { sanitizeUrlSegment } from "@/utils/routestrings";
function SliderSelfPaced({
  isHomePage,
  subCategories,
  programs,
  industryName,
  industryId
}: {
  isHomePage?: boolean;
  subCategories: Program[];
  programs: StandardLP[];
  industryName?: string;
  industryId?: number;
}) {
  const [isMobile, setIsMobile] = useState(typeof window !== "undefined" ? window.innerWidth < 992 : false);
  useEffect(() => {
    if (typeof window !== "undefined") {
      const handleResize = () => {
        setIsMobile(window.innerWidth < 992);
      };
      window.addEventListener("resize", handleResize);

      // Cleanup the event listener on component unmount
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);
  const [showAll, setShowAll] = useState(false);
  const visibleLearners = showAll || !isMobile ? subCategories : subCategories?.slice(0, 4);
  const swiperSettings = {
    modules: [Navigation, Mousewheel],
    slidesPerView: ("auto" as const),
    mousewheel: {
      forceToAxis: true,
      eventsTarget: ".section__selfpaced-slider-swiper"
    },
    spaceBetween: 32,
    breakpoints: {
      0: {
        spaceBetween: 10
      },
      768: {
        spaceBetween: 20
      },
      1024: {
        spaceBetween: 30
      }
    },
    navigation: {
      prevEl: ".section__selfpaced-arrow-previous--card",
      nextEl: ".section__selfpaced-arrow-nexts--card"
    }
  };
  const swiper = new Swiper(".section__selfpaced-slider-swiper", swiperSettings);
  useEffect(() => {
    swiper.init();
  }, []);
  return <div className="section__selfpaced-slider-container " data-sentry-component="SliderSelfPaced" data-sentry-source-file="index.tsx">
      <div className="section__selfpaced-slider-header section__container--small">
        <div className="alternative_header">
          <p className="alternative_header-caption">Learn at Your Own Pace</p>
          <h2>Self-Paced Programs</h2>
          <p className="alternative_header-description">
            Enhance your skills at your own pace with our flexible Self-Paced
            Programs, featuring video tutorials and practical exercises. Perfect
            for busy schedules, these courses offer independent learning without
            live sessions.
          </p>
        </div>

        <div className="section__selfpaced-arrows">
          {isHomePage && industryName && <Link href={`/in/${sanitizeUrlSegment(industryName)}${routeVariables.PREMIUMINDUSTRY}${industryId}`} className="section__programs-arrows-see_all">
              See All
            </Link>}

          <SliderArrows slidesLength={visibleLearners?.length} prevButtonClassName={swiperSettings.navigation.prevEl.slice(1)} nextButtonClassName={swiperSettings.navigation.nextEl.slice(1)} data-sentry-element="SliderArrows" data-sentry-source-file="index.tsx" />
        </div>
      </div>
      <div className="section__selfpaced-slider">
        <div className="section__selfpaced-slider-swiper swiper-learners">
          <div className="swiper-wrapper">
            {visibleLearners?.map((learner, index) => <SwiperSlide key={index}>
                <SlideSelfPaced learner={learner} program={programs.find(p => Number(p.attributes.productId) === learner.product_id) || programs[0]} />
              </SwiperSlide>)}
          </div>
        </div>
        <div className="section__selfpaced-slider-swiper-mobile">
          {visibleLearners?.map((learner, index) => <SlideSelfPaced learner={learner} key={index} program={programs.find(p => Number(p.attributes.productId) === learner.product_id) || programs[0]} />)}
        </div>
        {isHomePage && industryName ? subCategories?.length > 0 && <Link href={`/in/${sanitizeUrlSegment(industryName)}${routeVariables.PREMIUMINDUSTRY}${industryId}`} className="section__programs-slider-swiper-button">
                See More
              </Link> : !showAll && subCategories?.length > 4 && <button onClick={() => setShowAll(true)} className="section__programs-slider-swiper-button">
                See More
              </button>}
      </div>
    </div>;
}
function SlideSelfPaced({
  learner,
  program
}: {
  learner: Program;
  program: StandardLP;
}) {
  const pathName = usePathname();
  return <Link href={`/in/${sanitizeUrlSegment(program.attributes.name)}${routeVariables.PREMIUMCOURSE[1]}${program.attributes.productId}`} className="section__selfpaced-slider-card anchor-card m-1" onClick={() => {
    setItem("Program Name", learner?.name);
    setItem("Program Type", learner?.programType);
    if (typeof window !== "undefined" && typeof window.gtag === "function") {
      window.gtag("config", process.env.NEXT_PUBLIC_GA_ID, {
        event_label: "Click event on Self-Paced program",
        page_path: `${process.env.NEXT_PUBLIC_APP_URL}${pathName}`
      });
    }
  }} data-sentry-element="Link" data-sentry-component="SlideSelfPaced" data-sentry-source-file="index.tsx">
      <div style={{
      color: "inherit",
      textDecoration: "none"
    }} className="wrapper-div">
        <div className="w-full border-b border-bottle-green">
          <Image className="w-full h-[178px] object-cover" width={315} height={178} alt="course_thumbnail" src={program.attributes.banner_image.data?.attributes.url || ""} data-sentry-element="Image" data-sentry-source-file="index.tsx" />
        </div>
        <div className="section__selfpaced-slider-card-container">
          <div className="section__selfpaced-slider-card-header">
            <h3>{program.attributes.name}</h3>
            <div className="section__selfpaced-slider-card-stats">
              <div className="section__selfpaced-slider-card-stats-item">
                <div className="section__selfpaced-slider-card-stats-item-icon">
                  <Image width={17} height={17} src={GSIconVideo} alt="GSIconVideo" loading="lazy" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
                </div>
                <p>{learner?.duration}</p>
              </div>
            </div>
          </div>
          {program.attributes.premium_mentors.data.length > 0 && <div className="section__selfpaced-slider-slide-footer">
              <div className="section__selfpaced-slider-slide-footer-images">
                {program.attributes.premium_mentors.data.map((mentor, index) => <div className="section__selfpaced-slider-slide-footer-images-img" key={index}>
                      <Image src={mentor.attributes.mentorImage.data?.attributes.url || ""} alt="profilePhoto" width={40} height={40} className="" loading="lazy" />
                    </div>)}
                {program.attributes.premium_mentors.data.length > 3 && <div className="section__selfpaced-slider-slide-footer-images-img">
                    +{program.attributes.premium_mentors.data.length - 3}
                  </div>}
              </div>
              {program.attributes.premium_mentors.data.length > 1 ? <p>
                  Multiple mentors from{" "}
                  <span>
                    {formatStringArray(program.attributes.premium_mentors.data.map(mentor => mentor.attributes.companyName))}
                  </span>
                </p> : <p>
                  {program.attributes.premium_mentors.data[0]?.attributes?.name}{" "}
                  <span>
                    {program.attributes.premium_mentors.data[0]?.attributes?.designation}
                    {program.attributes.premium_mentors.data[0]?.attributes?.companyName && `, ${program.attributes.premium_mentors.data[0]?.attributes?.companyName}`}
                  </span>
                </p>}
            </div>}
        </div>
      </div>
    </Link>;
}
export default SliderSelfPaced;