"use client";

import { Program, StandardLP } from "@/services/types";
import { formatStringArray } from "@/utils/Functions";
import { setItem } from "@/utils/Functions/storageUtils";
import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { Mousewheel, Navigation } from "swiper/modules";
import { Swiper } from "swiper";
import { SwiperSlide } from "swiper/react";
import GSIconColumn from "../../../../../public/images/new/GS-Icon-Column.svg";
import GSIconLive from "../../../../../public/images/new/GS-Icon-Live.svg";
import GSIconCalender from "../../../../../public/images/new/GS-Icon-Calender.svg";
import React, { useEffect, useState } from "react";
import transformStrings from "@/utils/Functions/transformSessionType";
import { formatDateNormal } from "@/utils/Functions/formatDate";
import { routeVariables } from "@/utils/routestrings";
import SliderArrows from "../SliderArrows";
import { sanitizeUrlSegment } from "@/utils/routestrings";
function SliderMentorship({
  subCategories,
  programs,
  isHomePage,
  industryName,
  industryId
}: {
  subCategories: Program[];
  programs: StandardLP[];
  isHomePage?: boolean;
  industryName?: string;
  industryId?: number;
}) {
  const [isMobile, setIsMobile] = useState(typeof window !== "undefined" ? window.innerWidth < 992 : false);
  useEffect(() => {
    if (typeof window !== "undefined") {
      const handleResize = () => {
        setIsMobile(window.innerWidth < 992);
      };
      window.addEventListener("resize", handleResize);

      // Cleanup the event listener on component unmount
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);
  const [showAll, setShowAll] = useState(false);
  const visibleLearners = showAll || !isMobile ? subCategories : subCategories?.slice(0, 4);
  const swiperSettings = {
    modules: [Navigation, Mousewheel],
    slidesPerView: ("auto" as const),
    mousewheel: {
      forceToAxis: true,
      eventsTarget: ".section__programs-slider-swiper"
    },
    spaceBetween: 32,
    breakpoints: {
      0: {
        spaceBetween: 10
      },
      768: {
        spaceBetween: 20
      },
      1024: {
        spaceBetween: 30
      }
    },
    navigation: {
      prevEl: ".section__programs-arrow-prev--mentorship",
      nextEl: ".section__programs-arrow-next--mentorship"
    }
  };
  const swiper = new Swiper(".section__programs-slider-swiper", swiperSettings);
  useEffect(() => {
    swiper.init();
  }, []);
  return <div className="section__programs-slider-container" id="section--mentorships" data-sentry-component="SliderMentorship" data-sentry-source-file="index.tsx">
      <div className="section__programs-slider-header section__container--small">
        {isHomePage ? <h3>Mentorship Programs</h3> : <div className="alternative_header">
            <p className="alternative_header-caption">
              Expert Guidance for Career Growth
            </p>
            <h2>Mentorship Programs</h2>
            <p className="alternative_header-description">
              Experience live sessions, personalized feedback, and interactive
              assignments from industry experts. Our Mentorship Programs provide
              long-term guidance and practical experience to build a strong
              career foundation.
            </p>
          </div>}
        <div className="section__programs-arrows">
          {isHomePage && industryName && <Link href={`/in/${sanitizeUrlSegment(industryName)}${routeVariables.PREMIUMINDUSTRY}${industryId}`} className="section__programs-arrows-see_all">
              See All
            </Link>}

          <SliderArrows slidesLength={visibleLearners?.length} prevButtonClassName={swiperSettings.navigation.prevEl.slice(1)} nextButtonClassName={swiperSettings.navigation.nextEl.slice(1)} data-sentry-element="SliderArrows" data-sentry-source-file="index.tsx" />
        </div>
      </div>
      <div className="section__programs-slider">
        <div className="section__programs-slider-swiper swiper-program">
          <div className="swiper-wrapper">
            {visibleLearners?.map((learner, index) => <SwiperSlide key={index}>
                <SlideMentorship learner={learner} program={programs.find(p => Number(p.attributes.productId) === learner.product_id) || programs[0]} />
              </SwiperSlide>)}
          </div>
        </div>

        <div className="section__programs-slider-swiper-mobile">
          {visibleLearners?.map((learner, index) => <SlideMentorship learner={learner} program={programs.find(p => Number(p.attributes.productId) === learner.product_id) || programs[0]} key={index} />)}
        </div>

        {isHomePage && industryName ? subCategories?.length > 0 && <Link href={`/in/${sanitizeUrlSegment(industryName)}${routeVariables.PREMIUMINDUSTRY}${industryId}`} className="section__programs-slider-swiper-button">
                See More
              </Link> : !showAll && subCategories?.length > 4 && <button onClick={() => setShowAll(true)} className="section__programs-slider-swiper-button">
                See More
              </button>}
      </div>
    </div>;
}
function SlideMentorship({
  learner,
  program
}: {
  learner: Program;
  program: StandardLP;
}) {
  const pathName = usePathname();
  return <Link href={`/in/${sanitizeUrlSegment(program?.attributes.name)}${routeVariables.PREMIUMCOURSE[0]}${learner?.product_id}`} className="section__programs-slider-slide" onClick={() => {
    setItem("Program Name", learner?.name);
    setItem("Program Type", learner?.programType);
    if (typeof window !== "undefined" && typeof window.gtag === "function") {
      window.gtag("config", process.env.NEXT_PUBLIC_GA_ID, {
        event_label: "Click event on Mentorship Program",
        page_path: `${process.env.NEXT_PUBLIC_APP_URL}${pathName}`
      });
    }
  }} data-sentry-element="Link" data-sentry-component="SlideMentorship" data-sentry-source-file="index.tsx">
      <div className="section__programs-slider-slide-img">
        <Image src={program.attributes.banner_image.data?.attributes.url || ""} alt="banner" width={442} height={276} className="" loading="lazy" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
      </div>
      <div className="section__programs-slider-slide-text">
        {/* <span className="section__programs-slider-slide-text-badge">
            Bestseller
          </span> */}
        <div className="section__programs-slider-slide-header ">
          <h3>{program?.attributes.name}</h3>
          <p>{program?.attributes.sub_headline}</p>
          <div className="section__programs-slider-slide-header-stats">
            <div className="section__programs-slider-slide-header-stats-item">
              <div className="section__programs-slider-slide-header-stats-item-icon">
                <Image width={17} height={17} src={GSIconColumn} alt="GSIconColumn" loading="lazy" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
              </div>
              <p>{learner?.duration}</p>
            </div>
            {learner.sessions_type && <div className="section__programs-slider-slide-header-stats-item">
                <div className="section__programs-slider-slide-header-stats-item-icon">
                  <Image width={17} height={17} src={GSIconLive} alt="GSIconColumn" />
                </div>
                <p>{transformStrings(learner.sessions_type)}</p>
              </div>}
          </div>
          {learner.mappedCohort_StartDate && <div className="section__programs-slider-slide-header-stats section__programs-slider-slide-header-stats--hover">
              <div className="section__programs-slider-slide-header-stats-item">
                <div className="section__programs-slider-slide-header-stats-item-icon">
                  <Image width={17} height={17} src={GSIconCalender} alt="GSIconCalendar" />
                </div>
                <p>
                  {new Date(learner.mappedCohort_StartDate) < new Date() ? "Coming Soon" : formatDateNormal(learner.mappedCohort_StartDate)}
                </p>
              </div>
            </div>}
        </div>
        {program.attributes.premium_mentors.data.length > 0 && <div className="section__programs-slider-slide-footer">
            <div className="section__programs-slider-slide-footer-images">
              {program.attributes.premium_mentors.data.slice(0, 3)?.map((mentor, index) => <div className="section__programs-slider-slide-footer-images-img" key={index}>
                    <Image src={mentor.attributes.mentorImage.data?.attributes.url || ""} alt="profilePhoto" width={40} height={40} className="" loading="lazy" />
                  </div>)}
              {program.attributes.premium_mentors.data.length > 3 && <div className="section__programs-slider-slide-footer-images-img">
                  +{program.attributes.premium_mentors.data.length - 3}
                </div>}
            </div>
            {program.attributes.premium_mentors.data.length > 1 ? <p>
                Multiple mentors from{" "}
                <span>
                  {formatStringArray(program.attributes.premium_mentors.data.map(mentor => mentor.attributes.companyName))}
                </span>
              </p> : <p>
                {program.attributes.premium_mentors.data[0]?.attributes?.name}{" "}
                <span>
                  {program.attributes.premium_mentors.data[0]?.attributes?.designation}
                  {program.attributes.premium_mentors.data[0]?.attributes?.companyName && `, ${program.attributes.premium_mentors.data[0]?.attributes?.companyName}`}
                </span>
              </p>}
          </div>}
      </div>
    </Link>;
}
export default SliderMentorship;